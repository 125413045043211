import { withQueryParamsMapped, StringParam, DecodedValueMap, SetQuery, BooleanParam } from 'use-query-params';
import { AppLogin } from './AppLogin';

const queryConfig = {
  token: StringParam,
  providerId: StringParam,
  figmaFile: StringParam,
  visited: BooleanParam,
};

function mapQueryParamsToProps(query: DecodedValueMap<typeof queryConfig>, setQuery: SetQuery<typeof queryConfig>) {
  return {
    token: query.token,
    providerId: query.providerId,
    visited: query.visited,
    figmaFile: query.figmaFile,
    onSetVisited: (visited: boolean) => setQuery({ visited: !!visited }, 'replaceIn'),
  };
}

export default withQueryParamsMapped(queryConfig, mapQueryParamsToProps, AppLogin);
