/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export interface ApiUtilOptions extends RequestInit {
  /** An object of params that will be appened to the URL string.  Should be {key: value} where key is the param name and value is its value */
  params?: Record<string, unknown>;
  /** Access token to apply to the request as the bearer token */
  accessToken?: string;
  /*** Content type that is expected */
  contentType?: string;
  /** Turns on console logging */
  trace?: boolean;
  /** Prevents promise failure if there was an error.  Otherwise, the promise will fail if the response is not ok */
  ignoreError?: boolean;
  /** Normally, the response will be the json object response.  Set to true to get the actual response from fetch */
  fetchResponse?: boolean;
}

function paramsToString(params?: Record<string, unknown>): string {
  if (!params) {
    return '';
  }
  let result = '';
  Object.keys(params).forEach((key, index) => {
    if (index > 0) {
      result += '&';
    }
    const value: string = params[key] === undefined || params[key] === null ? '' : `${params[key]}`;
    result += `${key}=${encodeURI(value)}`;
  });
  return result;
}

export function fetch(url: string, init?: ApiUtilOptions): Promise<unknown> {
  let newUrl = (url || '') as string;
  const newInit = { ...init };
  newInit.headers = { ...newInit.headers } as Record<string, string>;
  if (newInit.params) {
    if (newUrl.indexOf('?') === -1) {
      newUrl = `${newUrl}?`;
    } else {
      newUrl = `${newUrl}&`;
    }
    newUrl += paramsToString(newInit.params);
  }
  if (newInit.accessToken) {
    newInit.headers.Authorization = `Bearer ${newInit.accessToken}`;
  }
  if (!newInit.headers['Content-Type']) {
    newInit.headers['Content-Type'] = 'application/json';
  }
  if (newInit.contentType) {
    newInit.headers['Content-Type'] = newInit.contentType;
  }
  if (newInit.headers['Content-Type'] !== 'application/json') {
    newInit.fetchResponse = true;
  }
  if (newInit.fetchResponse) {
    newInit.ignoreError = true;
  }
  if (newInit.trace) {
    console.log('** FETCH: ', newUrl, newInit);
  }
  return window.fetch(newUrl, newInit).then((response) => {
    if (!newInit.ignoreError && (!response || !response.ok)) {
      console.error('** FETCH error: ', newUrl);
      console.error('** FETCH response: ', response);
      throw response;
    }
    if (newInit.fetchResponse) {
      return response;
    }
    return response.json();
  });
}

export function get<T = unknown>(url: string, init?: ApiUtilOptions): Promise<T> {
  return fetch(url, init).then((result) => {
    return result as T;
  });
}

export function post<T = unknown>(url: string, body: unknown, init?: ApiUtilOptions): Promise<T> {
  if (!body || typeof body !== 'object') {
    throw new Error('Invalid non-object body');
  }
  const newOptions: any = { ...init };
  newOptions.method = 'POST';
  newOptions.body = JSON.stringify(body);
  return get<T>(url, newOptions);
}

export function put<T = unknown>(url: string, body: any, init?: ApiUtilOptions): Promise<T> {
  if (!body || typeof body !== 'object') {
    throw new Error('Invalid non-object body');
  }
  const newOptions: any = { ...init };
  newOptions.method = 'PUT';
  newOptions.body = JSON.stringify(body);
  return get<T>(url, newOptions);
}

export function del(url: string, init?: ApiUtilOptions): Promise<void> {
  const newOptions: any = { ...init };
  newOptions.method = 'DELETE';
  return get<void>(url, newOptions);
}
