import { Children } from 'react';
import styles from './styles.module.scss';

export interface ListProps {
  simple?: boolean;
  vertical?: boolean;
  padding?: string;
  spacing?: string;
  align?: string;
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse';
  children?: React.ReactNode;
}

export const List: React.FC<ListProps> = ({
  children,
  spacing,
  padding,
  vertical,
  wrap = 'nowrap',
  simple,
  align = 'center',
}) => {
  if (!children) {
    return null;
  }
  const renderChild = (child: any, index: number): React.ReactNode => {
    const style: any = {};
    if (spacing && index) {
      if (vertical) {
        style.marginTop = spacing;
      } else {
        style.marginLeft = spacing;
      }
    }
    if (simple) {
      return (
        <div key={child.key} style={style} className={styles.listItem}>
          {child}
        </div>
      );
    }
    return (
      <li key={child.key} style={style} className={styles.listItem}>
        {child}
      </li>
    );
  };
  const style: any = {
    flexWrap: wrap,
    alignItems: align,
  };
  if (padding) {
    style.padding = padding;
  }
  if (vertical) {
    style.flexDirection = 'column';
  }
  const renderChildren = () => {
    const arr = Children.toArray(children);
    if (!arr || !arr.length) {
      return null;
    }
    return arr.map(renderChild);
  };
  if (simple) {
    return (
      <div style={style} className={styles.list}>
        {renderChildren()}
      </div>
    );
  }
  return (
    <ul style={style} className={styles.list}>
      {renderChildren()}
    </ul>
  );
};
