import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { AppStateContext } from '../context/AppStateContext';
import { PATH as PATH_AUTHORIZE } from '../views/Authorize';

export const AuthorizedRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const appState = useContext(AppStateContext);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search || '');
  if (!appState.appState.accessCode) {
    return <Redirect to={`${PATH_AUTHORIZE}?figmaFile=${searchParams.get('figmaFile') || ''}`} />;
  }
  return <Route {...props} />;
};
