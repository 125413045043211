import { withQueryParamsMapped, StringParam, DecodedValueMap } from 'use-query-params';
import { Cloning } from './Cloning';

const queryConfig = {
  project: StringParam,
  providerId: StringParam,
  figmaFile: StringParam,
};

function mapQueryParamsToProps(query: DecodedValueMap<typeof queryConfig>) {
  return {
    project: query.project,
    providerId: query.providerId,
    figmaFile: query.figmaFile,
  };
}

export default withQueryParamsMapped(queryConfig, mapQueryParamsToProps, Cloning);
