import { getEnvironment } from '../utils/environment';

export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId?: string;
}

let FIREBASE_CONFIG: FirebaseConfig = {
  apiKey: 'AIzaSyDV_rct-QmhCdAEsbocp94aM6klrXjjoVs',
  authDomain: 'recursica.firebaseapp.com',
  projectId: 'recursica',
  storageBucket: 'recursica.appspot.com',
  messagingSenderId: '294662438596',
  appId: '1:294662438596:web:1b729481a9ca4a23051391',
  measurementId: 'G-47XBKR34M4',
};

if (getEnvironment().development) {
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBEEpbFQ3EIIZKMcKoaspuJMaC7nWXQtro',
    authDomain: 'recursica-dev.firebaseapp.com',
    projectId: 'recursica-dev',
    storageBucket: 'recursica-dev.appspot.com',
    messagingSenderId: '645861892654',
    appId: '1:645861892654:web:2159fec1cb3410622bb5bf',
  };
}

export { FIREBASE_CONFIG };
