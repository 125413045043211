import { withQueryParamsMapped, StringParam, DecodedValueMap } from 'use-query-params';
import { PluginGetProvider } from './PluginGetProvider';

const queryConfig = {
  token: StringParam,
  figmaFile: StringParam,
};

function mapQueryParamsToProps(query: DecodedValueMap<typeof queryConfig>) {
  return {
    token: query.token,
    figmaFile: query.figmaFile,
  };
}

export default withQueryParamsMapped(queryConfig, mapQueryParamsToProps, PluginGetProvider);
