import React from 'react';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import { Button } from 'components/Button/Button';
import styles from './styles.module.scss';
import { List } from 'components/List/List';
import { Error } from 'components/Error/Error';
import { formatErrorDescription } from 'utils/formatErrorDescription';
import { formatFigmaFileUri } from 'utils/formatFigmaFileUri';

const TOKEN_ERROR = 'The token provided by the plugin is invalid.  You need to restart the plugin and try again';

interface PluginCompleteProps {
  token: string;
  errorResult: string;
  errorDescription: string;
  result: string;
  providerId: string;
  figmaFile: string;
}

function _isTokenError(errorDescription: string): boolean {
  return errorDescription === 'Invalid token';
}

export const PluginComplete: React.FC<PluginCompleteProps> = ({
  token,
  errorResult,
  errorDescription,
  result,
  providerId,
  figmaFile,
}) => {
  if (result === 'success') {
    const openFigmaUri = formatFigmaFileUri(figmaFile);
    return (
      <View title={'Login Complete'}>
        <div className={styles.page}>
          <Heading padding={'0 0 100px 0'}>All set! Back to Figma we go.</Heading>
          <p>{`The Recursica plugin should be successfully logged in.  You can close this window`}</p>
          {openFigmaUri ? <Button id={'OpenFigma'} text={'Open Figma'} target={'_blank'} href={openFigmaUri} /> : null}
        </div>
      </View>
    );
  }
  const isTokenError = _isTokenError(errorDescription);
  return (
    <View title={'Login Error'}>
      <div className={styles.page}>
        <Heading padding={'0 0 60px 0'}>Error</Heading>
        <p className={styles.errorDescription}>{`It looks like an error occurred during authentication${
          providerId ? `with ${providerId}` : ''
        }`}</p>
        <Error message={isTokenError ? TOKEN_ERROR : formatErrorDescription(errorDescription)} />
        {!isTokenError && (
          <List spacing={'30px'}>
            <Button id={'Retry'} text={'Retry'} href={`/plugin-login?token=${token}&providerId=${providerId}`} />
            <Button id={'Cancel'} text={'Cancel'} href={'/start'} />
          </List>
        )}
      </div>
    </View>
  );
};
