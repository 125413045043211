import { Link } from 'react-router-dom';
import { forwardRef } from 'react';
import './styles.scss';

export interface ButtonProps {
  id: string;
  text: string;
  onClick?: (id?: string) => void;
  disabled?: boolean;
  className?: string;
  href?: string;
  to?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  complex?: boolean;
  children?: React.ReactNode;
}

export const Button = forwardRef((props: ButtonProps | any, ref?: React.Ref<HTMLButtonElement>) => {
  const { text, onClick, className, children, href, target, complex, disabled, to, ...additionalProps } = props;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (onClick) {
      onClick(props.id);
    }
  };
  const getClassName = () => {
    let newClassName = 'Button';
    if (className) {
      newClassName += ` ${className}`;
    }
    return newClassName;
  };
  let isDisabled = disabled || !onClick;
  if (href !== undefined || to !== undefined) {
    let newTo = to;
    const newTarget = target || '_self';
    if (!to && href && href.indexOf('/') === 0) {
      newTo = href;
    }
    isDisabled = disabled || (!newTo && !href);
    if (newTo) {
      return (
        <Link
          {...additionalProps}
          disabled={isDisabled}
          className={getClassName()}
          ref={ref}
          to={newTo}
          target={newTarget}
          rel={newTarget === '_blank' ? 'noopener noreferrer' : ''}
        >
          {children || text}
        </Link>
      );
    }
    return (
      <a
        {...additionalProps}
        disabled={isDisabled}
        className={getClassName()}
        ref={ref}
        href={href}
        target={newTarget}
        rel={newTarget === '_blank' ? 'noopener noreferrer' : ''}
      >
        {children || text}
      </a>
    );
  }
  if (children) {
    if (complex === undefined) {
      throw new Error('Button: You must specify complex if using children for button component');
    }
    if (complex) {
      return (
        <div
          {...additionalProps}
          disabled={isDisabled}
          role={'button'}
          ref={ref}
          onClick={handleClick}
          className={getClassName()}
        >
          {children || text}
        </div>
      );
    }
  }
  return (
    <button {...additionalProps} disabled={isDisabled} ref={ref} onClick={handleClick} className={getClassName()}>
      {children || text}
    </button>
  );
});
Button.displayName = 'Button';
