import { useState } from 'react';

export function useLoadingAsync(): [boolean, <T extends unknown>(asyncResult: Promise<T>) => Promise<T>] {
  const [loading, setLoading] = useState(false);
  const callAsync = <T extends unknown>(asyncResult: Promise<T>): Promise<T> => {
    return asyncResult
      .then((result) => {
        setLoading(false);
        return result;
      })
      .catch((error) => {
        setLoading(false);
        throw error;
      });
  };
  return [loading, callAsync];
}
