import React, { useContext, useState } from 'react';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import { TextInput } from '../../components/TextInput/TextInput';
import { SubmitButton } from '../../components/SubmitButton/SubmitButton';
import styles from './styles.module.scss';
import { apiGetCheckAccessCode } from 'api/apiGetCheckAccessCode';
import { useHistory } from 'react-router-dom';
import { AppStateContext } from 'context/AppStateContext';
import { useError } from 'hooks/useError';
import { useLoadingAsync } from 'hooks/useLoadingAsync';
import { Error } from '../../components/Error/Error';
import { PATH as PATH_START } from 'views/Start';

export interface AuthorizeProps {
  figmaFile: string;
}

export const Authorize: React.FC<AuthorizeProps> = ({ figmaFile }) => {
  const [password, setPassword] = useState('');
  const [submitting, callAsync] = useLoadingAsync();
  const [error, setError] = useError();
  const history = useHistory();
  const appState = useContext(AppStateContext);
  const setValidCode = (accessCode: string) => {
    appState.setAppState({ accessCode });
    history.replace(`${PATH_START}?figmaFile=${figmaFile || ''}`);
  };
  const clearAccessCode = () => {
    if (appState.appState.accessCode) {
      appState.setAppState({ accessCode: null });
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPassword = password.trim().trimLeft();
    if (!newPassword) {
      return;
    }
    setError();
    callAsync(apiGetCheckAccessCode(password))
      .then((response) => {
        switch (response.valid) {
          case 'valid':
            setValidCode(response.accessCode);
            break;
          case 'notValid':
            clearAccessCode();
            setError('This access code is no longer valid');
            break;
          default:
            setError('This access code is not valid');
        }
      })
      .catch((error) => {
        setError('An unknown error occurred checking this access code.  Please try again later', error);
      });
  };
  const handleChangeValue = (value: string) => {
    if (error) {
      setError();
    }
    setPassword(value);
  };
  return (
    <View title={'Tell us a secret'} headerBackToWebsiteButton>
      <Heading h1 padding={'0 0 40px 0'}>
        Tell us a secret
      </Heading>
      <form onSubmit={handleSubmit}>
        <div className={styles.passwordInput}>
          <TextInput
            id={'SecretInput'}
            placeholder={'Password...'}
            width={'370px'}
            value={password}
            onChangeValue={handleChangeValue}
            disabled={submitting}
            type={'password'}
          />
          <SubmitButton id={'SubmitSecret'} disabled={!password || submitting} />
        </div>
      </form>
      <Error message={error} />
    </View>
  );
};
