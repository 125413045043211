import React from 'react';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import { Button } from '../../components/Button/Button';
import styles from './styles.module.scss';
import { formatFigmaFileUri } from 'utils/formatFigmaFileUri';

interface BackToFigmaProps {
  figmaFile: string;
}

export const BackToFigma: React.FC<BackToFigmaProps> = ({ figmaFile }) => {
  const openFigmaUri = formatFigmaFileUri(figmaFile);
  return (
    <View title={'Back To Figma'}>
      <div className={styles.page}>
        <Heading>All set! Back to Figma we go.</Heading>
        <p>
          Your new project was created and it’s time to pull all those fancy components into Figma. Be sure to select
          the <b>recursica-materialui-sandbox</b> from the option list.
        </p>
        {openFigmaUri ? <Button id={'OpenFigma'} text={'Open Figma'} target={'_blank'} href={openFigmaUri} /> : null}
      </div>
    </View>
  );
};
