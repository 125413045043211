/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/ban-types */
import React, { useEffect, useState, useCallback, useContext } from 'react';
import { AppStateContext } from 'context/AppStateContext';
import { userStore } from 'auth';

const Auth: React.FC<any> = ({ children }) => {
  const appState = useContext(AppStateContext);
  const [userInitialized, setUserInitialized] = useState(false);
  const handleUserChange = useCallback(() => {
    const currentUser = userStore.getCurrentUser();
    if (!currentUser) {
      if (userInitialized) {
        console.warn('Logging out current user');
      }
    } else {
      console.warn('User logged in: ', currentUser.email);
    }
    appState.setAppState({ currentUser });
    setUserInitialized(true);
  }, []);
  useEffect(() => {
    userStore.subscribe(handleUserChange);
    return () => {
      userStore.unsubscribe(handleUserChange);
    };
  }, []);
  return userInitialized ? children : null;
};

export default Auth;
