import { useState } from 'react';

export function useError(): [string, (message?: string, error?: unknown) => void, unknown] {
  const [msg, setErrorMsg] = useState('');
  const [error, setErrorObj] = useState<unknown>();
  const setError = (message?: string, error?: unknown) => {
    if (message === undefined && error === undefined) {
      setErrorMsg('');
      setErrorObj(undefined);
      return;
    }
    const newMessage = message || 'An unknown error occured';
    console.error(newMessage, error);
    setErrorMsg(newMessage);
    setErrorObj(error);
  };
  return [msg, setError, error];
}
