import { createGetApi } from 'api-layer';
import { getEnvironment, formatEnvUrl } from 'utils/environment';
import { get } from '../../utils/apiUtils';

const APIS: any = {
  localhost: '/recursica-dev/us-central1/app-authorize',
  'recursica-dev': '/api/authorize',
  recursica: '/api/authorize',
};
export interface ApiResponse {
  token: string;
  accessCode: string;
}

function getAuthorize(accessCode: string): Promise<ApiResponse> {
  const url = formatEnvUrl(getEnvironment(), APIS[getEnvironment().type]);
  const params = {
    accessCode,
  };
  return get<ApiResponse>(url, { params });
}

export const apiGetAuthorize = createGetApi(getAuthorize, 'getAuthorize.mock.json');
