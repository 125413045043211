/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { AppState, AppStateContext } from 'context/AppStateContext';
import { initApp } from '../initApp';
import { saveAppState } from './persistAppState';
import { apiGetCheckAccessCode } from 'api/apiGetCheckAccessCode';

interface AppStateProviderProps {
  initialState?: AppState;
}

const AppStateProvider: React.FC<AppStateProviderProps> = ({ initialState, children }) => {
  let newState = {};
  if (initialState) {
    newState = { ...initialState, ...{ initialized: true } };
  }
  const [appState, setCurrentAppState] = useState<AppState>(newState);
  const setAppState = (state: AppState) => {
    const newState = { ...appState, ...state };
    saveAppState(newState);
    setCurrentAppState(newState);
  };
  const checkAccessCode = () => {
    if (appState.accessCode) {
      apiGetCheckAccessCode(appState.accessCode)
        .then((valid) => {
          if (!valid) {
            const newState = { ...appState, ...{ accessCode: null } };
            setAppState(newState);
          }
        })
        .catch((error) => {
          console.error('Unknown Error checking access code', error);
        });
    }
  };
  useEffect(() => {
    // initialize the application
    if (!initialState) {
      initApp().then((state: AppState) => {
        const appState: AppState = state || {};
        appState.initialized = true;
        setCurrentAppState(appState);
        // Check the access code in parallel to unauthorize it if its not set
        checkAccessCode();
      });
      return;
    }
    // Check the current access code if provided
    checkAccessCode();
  }, []);
  if (appState.initialized) {
    return <AppStateContext.Provider value={{ appState, setAppState }}>{children}</AppStateContext.Provider>;
  }
  return null;
};

export default AppStateProvider;
