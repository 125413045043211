export function formatErrorDescription(errorDescription: string): string {
  if (!errorDescription) {
    return 'An unknown error occured';
  }
  switch (errorDescription) {
    case 'redirect_uri_mismatch':
      return 'The Recursica environment is not registered with the provider properly.  Contact the Recursica team';
    case 'Invalid token':
      return 'The token provided is invalid.  Please retry again';
    default:
      return errorDescription;
  }
}
