import React from 'react';
import { View } from '../View';
import { Heading } from 'components/Heading/Heading';
import { Button } from 'components/Button/Button';
import styles from './styles.module.scss';
import { PATH as PATH_BACK_TO_FIGMA } from 'views/BackToFigma';

export interface CloningProps {
  providerId: string;
  project: string;
  figmaFile: string;
}

export const Cloning: React.FC<CloningProps> = ({ figmaFile }) => {
  return (
    <View title={'Cloning'}>
      <div className={styles.page}>
        <Heading padding={'0 0 80px 0'}>Cloning...</Heading>
        <p>
          Not to worry. It’s not like we’re making Dolly or anything. We’re just copying the files from the component
          library into your project. Hang tight.
        </p>
        <Button
          id={'TakingTooLong'}
          text={'This seems to be taking too long'}
          href={`${PATH_BACK_TO_FIGMA}?figmaFile=${figmaFile || ''}`}
        />
      </div>
    </View>
  );
};
