/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import { User } from 'auth';

export interface AppState {
  initialized?: boolean;
  currentUser?: User | null;
  accessCode?: string | null;
  completedSetup?: boolean;
  showedOauthDescription?: boolean;
}

export const DEFAULT_APP_STATE: AppState = {
  initialized: false,
  currentUser: null,
  accessCode: null,
  completedSetup: false,
  showedOauthDescription: false,
};

export interface AppStateContextProps {
  appState: AppState;
  setAppState: (state: AppState) => void;
}

export const AppStateContext = createContext<AppStateContextProps>({
  appState: {},
  setAppState: () => {},
});
