/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import { Button } from '../../components/Button/Button';
import styles from './styles.module.scss';
import { PATH as PATH_LOGIN } from '../AppLogin';
import { PATH as PATH_GET_PROVIDER } from 'views/GetProvider';
import { useError } from 'hooks/useError';
import { getAuthToken } from 'auth/authentication';
import { AppStateContext } from 'context/AppStateContext';
import { Error } from 'components/Error/Error';

export interface OauthDescriptionProps {
  providerId: string;
  figmaFile: string;
}

export const OauthDescription: React.FC<OauthDescriptionProps> = ({ providerId, figmaFile }) => {
  const [token, setToken] = useState('');
  const [error, setError] = useError();
  const appState = useContext(AppStateContext);
  useEffect(() => {
    getAuthToken(appState.appState.accessCode)
      .then((result) => {
        setToken(result);
      })
      .catch((error) => {
        setError('An error occured starting authentication', error);
      });
  }, []);
  if (!providerId) {
    console.warn('No providerId for OauthDescription view.  Redirecting back to get provider');
    return <Redirect to={`${PATH_GET_PROVIDER}?figmaFile=${figmaFile || ''}`} />;
  }
  return (
    <View title={'Auth Description'}>
      <div className={styles.page}>
        <Heading>Here's what happens now.</Heading>
        <p>
          This will pop-up a new window for you to sign into your account. We’re doing this cool “technical handshake”
          thingy. Be sure to sign in to your account and <b>grant Recursica permission</b>. If something goes wrong, you
          can always try again.
        </p>
        <Button
          id={'ContinueOauth'}
          text={`Ok, let's do it`}
          to={`${PATH_LOGIN}?providerId=${providerId}&token=${token}&figmaFile=${figmaFile || ''}`}
          disabled={!token}
        />
        <Error message={error} />
      </div>
    </View>
  );
};
