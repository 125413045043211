import React from 'react';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import { Button } from '../../components/Button/Button';
import { PROVIDER_ID } from 'auth/authentication';
import styles from './styles.module.scss';
import { List } from 'components/List/List';
import { PATH as NoProviderPath } from '../NoProvider';
import { PATH as OauthPath } from '../OauthDescription';

export interface GetProviderProps {
  figmaFile: string;
}

export const GetProvider: React.FC<GetProviderProps> = ({ figmaFile }) => {
  return (
    <View title={'Provider?'}>
      <div className={styles.page}>
        <Heading>
          Do you have a <em>Github</em> or <em>Gitlab</em> account?
        </Heading>
        <List spacing={'40px'} align={'flex-start'}>
          <Button
            id={'SelectGithub'}
            text={'Yes, Github'}
            to={`${OauthPath}?providerId=${PROVIDER_ID.github}&figmaFile=${figmaFile || ''}`}
          />
          <List spacing={'10px'} simple vertical>
            <Button
              id={'SelectGitlab'}
              text={'Yes, Gitlab'}
              to={`${OauthPath}?providerId=${PROVIDER_ID.gitlab}&figmaFile=${figmaFile || ''}`}
            />
            <span>Recommended</span>
          </List>
          <Button id={'SelectNope'} text={'Nope'} href={`${NoProviderPath}?figmaFile=${figmaFile || ''}`} />
        </List>
      </div>
    </View>
  );
};
