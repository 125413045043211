/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/namespace */
/* eslint-disable import/named */
import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { AppStateProvider } from './appState';
import { Auth, AuthorizedRoute, AuthenticatedRoute } from 'auth';
import * as AppLogin from 'views/AppLogin';
import * as Authorize from 'views/Authorize';
import * as Start from 'views/Start';
import * as GetProvider from 'views/GetProvider';
import * as NoProvider from 'views/NoProvider';
import * as Cloning from 'views/Cloning';
import * as OauthDescription from 'views/OauthDescription';
import * as PluginLogin from 'views/PluginLogin';
import * as PluginComplete from 'views/PluginComplete';
import * as BackToFigma from 'views/BackToFigma';
import * as AppLoginComplete from 'views/AppLoginComplete';
import * as PluginGetProvider from 'views/PluginGetProvider';

function App() {
  return (
    <AppStateProvider>
      <Auth>
        <Router>
          <QueryParamProvider ReactRouterRoute={Route}>
            <Switch>
              <AuthenticatedRoute path={Cloning.PATH} component={Cloning.COMPONENT} />
              <AuthorizedRoute path={PluginGetProvider.PATH} component={PluginGetProvider.COMPONENT} />
              <AuthorizedRoute path={AppLoginComplete.PATH} component={BackToFigma.COMPONENT} />
              <AuthorizedRoute path={BackToFigma.PATH} component={BackToFigma.COMPONENT} />
              <AuthorizedRoute path={PluginComplete.PATH} component={PluginComplete.COMPONENT} />
              <AuthorizedRoute path={OauthDescription.PATH} component={OauthDescription.COMPONENT} />
              <AuthorizedRoute path={NoProvider.PATH} component={NoProvider.COMPONENT} />
              <AuthorizedRoute path={GetProvider.PATH} component={GetProvider.COMPONENT} />
              <AuthorizedRoute path={Start.PATH} component={Start.COMPONENT} />
              <AuthorizedRoute path={AppLogin.PATH} component={AppLogin.COMPONENT} />
              <AuthorizedRoute path={PluginLogin.PATH} component={PluginLogin.COMPONENT} />
              <Route path={Authorize.PATH} component={Authorize.COMPONENT} />
              <AuthorizedRoute path={'/'} component={Start.COMPONENT} />
            </Switch>
          </QueryParamProvider>
        </Router>
      </Auth>
    </AppStateProvider>
  );
}

export default App;
