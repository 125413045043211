import { withQueryParamsMapped, StringParam, DecodedValueMap } from 'use-query-params';
import { AppLoginComplete } from './AppLoginComplete';

const queryConfig = {
  token: StringParam,
  error: StringParam,
  description: StringParam,
  result: StringParam,
  providerId: StringParam,
  figmaFile: StringParam,
};

function mapQueryParamsToProps(query: DecodedValueMap<typeof queryConfig>) {
  return {
    token: query.token,
    errorResult: query.error,
    errorDescription: query.description,
    result: query.result,
    providerId: query.providerId,
    figmaFile: query.figmaFile,
  };
}

export default withQueryParamsMapped(queryConfig, mapQueryParamsToProps, AppLoginComplete);
