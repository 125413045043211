/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Header } from '../components/Header/Header';
import { DevBadge } from '../components/DevBadge/DevBadge';
import { HeaderPadding } from '../components/HeaderPadding/HeaderPadding';
import styles from './view.module.scss';

interface ViewProps {
  title?: string;
  hideLogo?: boolean;
  header?: React.ReactNode;
  noHeaderPadding?: boolean;
  headerBackToWebsiteButton?: boolean;
}

export const View: React.FC<ViewProps> = ({
  children,
  hideLogo,
  header,
  noHeaderPadding,
  headerBackToWebsiteButton,
  title,
}) => {
  useEffect(() => {
    const newTitle = `Recursica${title ? ` - ${title}` : ''}`;
    if (document.title !== newTitle) {
      document.title = newTitle;
    }
  }, []);
  return (
    <div className={styles.view}>
      <div className={styles.header}>
        <Header hideLogo={hideLogo} backToWebsiteButton={headerBackToWebsiteButton}>
          {header}
        </Header>
      </div>
      <div className={styles.content}>
        {!noHeaderPadding && <HeaderPadding />}
        {children}
      </div>
      <div className={styles.footer}>
        <DevBadge />
      </div>
    </div>
  );
};
