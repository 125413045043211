/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { firebase } from 'firebaseApp';
import 'firebase/auth';
import { userStore, User } from './UserStore';
import { apiGetAuthorize } from '../api/apiGetAuthorize';
import { getEnvironment, formatEnvUrl } from 'utils/environment';

const GITHUB_AUTHORIZE_URL: any = {
  localhost: '/recursica-dev/us-central1/github-authorize',
  'recursica-dev': '/api/github/authorize',
  recursica: '/api/github/authorize',
};
const GITLAB_AUTHORIZE_URL: any = {
  localhost: '/recursica-dev/us-central1/gitlab-authorize',
  'recursica-dev': '/api/gitlab/authorize',
  recursica: '/api/gitlab/authorize',
};

export function auth() {
  return firebase.auth();
}
auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);

export function isAuthenticated(): boolean {
  return !!userStore.getCurrentUser();
}

export enum PROVIDER_ID {
  github = 'github.com',
  gitlab = 'gitlab.com',
  local = 'local',
}
export function getAuthToken(accessCode: string | null | undefined): Promise<string> {
  if (!accessCode) {
    throw new Error('Invalid argument');
  }
  return apiGetAuthorize(accessCode).then((response) => {
    return response.token;
  });
}

export function startAuth(
  accessCode: string | null | undefined,
  authToken: string,
  providerId: PROVIDER_ID,
  redirectUri: string,
  getLoginToken?: boolean,
  logoutPrevious = true,
  figmaFile?: string,
): void {
  if (!accessCode || !providerId || !redirectUri) {
    throw new Error('Invalid argument');
  }
  if (isAuthenticated()) {
    // We need to logout
    if (logoutPrevious) {
      userStore.logout();
    } else {
      throw new Error('User is already authorized');
    }
  }
  let url = '';
  const environment = getEnvironment();
  const newRedirectUri = `${window.location.origin}${redirectUri}`;
  switch (providerId) {
    case PROVIDER_ID.github:
      url = formatEnvUrl(
        environment,
        `${GITHUB_AUTHORIZE_URL[environment.type]}?token=${authToken}&redirect_uri=${newRedirectUri}`,
      );
      break;
    case PROVIDER_ID.gitlab:
      url = formatEnvUrl(
        environment,
        `${GITLAB_AUTHORIZE_URL[environment.type]}?token=${authToken}&redirect_uri=${newRedirectUri}`,
      );
      break;
    default:
      throw new Error('Invalid provider type');
  }
  if (getLoginToken) {
    url += '&login=true';
  }
  if (figmaFile) {
    url += `&figmaFile=${figmaFile}`;
  }
  window.open(url, '_self');
}

export function processAuthResponse(
  accessCode: string | null | undefined,
  token: string,
  providerId: PROVIDER_ID,
): Promise<User> {
  if (isAuthenticated()) {
    return Promise.resolve(userStore.currentUser as User);
  }
  if (!accessCode || !token) {
    return Promise.reject(new Error('Invalid argument'));
  }
  return auth()
    .signInWithCustomToken(token)
    .then((userCredential) => {
      userStore.setProvider(userCredential, providerId);
      return userStore.getCurrentUser() as User;
    });
}
