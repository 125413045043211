import React from 'react';
import { useLocation } from 'react-router-dom';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import { Button } from '../../components/Button/Button';
import styles from './styles.module.scss';
import { PATH as PATH_GET_PROVIDER } from 'views/GetProvider';

export interface StartProps {
  figmaFile: string;
}

export const Start: React.FC<StartProps> = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search || '');
  const figmaFile = searchParams.get('figmaFile') || '';
  return (
    <View title={'Whatcha up to?'}>
      <div className={styles.page}>
        <Heading>Whatcha up to?</Heading>
        <ul className={styles.options}>
          <li>
            <span>Just playing around</span>
            <Button id={'BuildSandbox'} text={'Build a Sandbox'} to={`${PATH_GET_PROVIDER}?figmaFile=${figmaFile}`} />
          </li>
          <li>
            <span>Create a new design system for a real project</span>
            <Button id={'CreateNewLibrary'} text={'Create New Library'} />
            <span>Coming Soon</span>
          </li>
          <li>
            <span>Connect to an existing project</span>
            <Button id={'ConnectToProject'} text={'Convert Repo to Recursica'} />
            <span>Coming Soon</span>
          </li>
        </ul>
      </div>
    </View>
  );
};
