import { withQueryParamsMapped, StringParam, DecodedValueMap } from 'use-query-params';
import { NoProvider } from './NoProvider';

const queryConfig = {
  ret: StringParam,
  figmaFile: StringParam,
};

function mapQueryParamsToProps(query: DecodedValueMap<typeof queryConfig>) {
  return {
    ret: query.ret,
    figmaFile: query.figmaFile,
  };
}

export default withQueryParamsMapped(queryConfig, mapQueryParamsToProps, NoProvider);
