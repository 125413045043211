/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AppStateContext } from 'context/AppStateContext';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import styles from './styles.module.scss';
import { PROVIDER_ID, startAuth } from 'auth/authentication';
import { PATH as LoginCompletePath } from 'views/AppLoginComplete';
import { List } from 'components/List/List';
import { Button } from 'components/Button/Button';

interface AppLoginProps {
  token: string;
  providerId: string;
  visited: boolean;
  figmaFile: string;
  onSetVisited: (visited: boolean) => void;
}

export const AppLogin: React.FC<AppLoginProps> = ({ token, providerId, figmaFile, visited, onSetVisited }) => {
  const appContext = useContext(AppStateContext);
  const currentUser = appContext.appState.currentUser;
  const handleStart = () => {
    if (currentUser && currentUser.repoProviderId === providerId) {
      return;
    }
    onSetVisited(true);
    if (token && providerId) {
      startAuth(
        appContext.appState.accessCode,
        token,
        providerId as PROVIDER_ID,
        LoginCompletePath,
        true,
        true,
        figmaFile,
      );
    }
  };
  useEffect(() => {
    if (visited) {
      return;
    }
    handleStart();
  }, []);
  if (currentUser && currentUser.repoProviderId === providerId) {
    return (
      <Redirect to={`${LoginCompletePath}?providerId=${providerId}&figmaFile=${figmaFile || ''}&result=success`} />
    );
  }
  if (!token || !providerId) {
    console.warn('WARNING: Missing plugin token provider. This should never happen');
    return <Redirect to={'/start'} />;
  }
  return (
    <View title={'Login'}>
      <div className={styles.page}>
        <Heading>Connecting...</Heading>
        <List spacing={'40px'} padding={'0 30px 0 0'}>
          <Button
            id={'all-done'}
            text={'All done'}
            href={`${LoginCompletePath}?providerId=${providerId}&figmaFile=${figmaFile || ''}&result=success`}
          />
          <Button id={'retry'} text={'Retry'} onClick={handleStart} />
        </List>
      </div>
    </View>
  );
};
