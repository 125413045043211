import React from 'react';
import { getEnvironment } from 'utils/environment';
import styles from './styles.module.scss';

export const DevBadge: React.FC<Record<string, never>> = () => {
  if (!getEnvironment().development) {
    return null;
  }
  return <div className={styles.badge}>DEV</div>;
};
