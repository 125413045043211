import React from 'react';
import { Link } from 'react-router-dom';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import { Button } from '../../components/Button/Button';
import { PATH as PATH_GET_PROVIDER } from 'views/GetProvider';
import styles from './styles.module.scss';

export interface NoProviderProps {
  ret: string;
  figmaFile: string;
}

export const NoProvider: React.FC<NoProviderProps> = ({ ret, figmaFile }) => {
  let returnUri = `${ret || PATH_GET_PROVIDER}`;
  if (returnUri.indexOf('?') < 0) {
    returnUri += `?figmaFile=${figmaFile || ''}`;
  } else {
    returnUri += `&figmaFile=${figmaFile || ''}`;
  }
  return (
    <View title={'Pick your provider'}>
      <div className={styles.page}>
        <Heading>Pick your poison</Heading>
        <p>Create a free account and come back to Recursica when you're done</p>
        <ul className={styles.options}>
          <li>
            <Button id={'PickGithub'} text={'Github'} href={'https://github.com/join'} />
          </li>
          <li>
            <Button id={'PickGitlab'} text={'Gitlab'} href={'https://about.gitlab.com/free-trial/'} />
            <span>Recommended</span>
          </li>
        </ul>
        <Link to={returnUri}>Alright, I'm ready to proceed with my fancy new account</Link>
      </div>
    </View>
  );
};
