/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Redirect } from 'react-router-dom';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import styles from './styles.module.scss';
import { PROVIDER_ID } from 'auth/authentication';
import { PATH as PluginLoginPath } from 'views/PluginLogin';
import { List } from 'components/List/List';
import { Button } from 'components/Button/Button';
import { getEnvironment } from 'utils/environment';

interface PluginGetProviderProps {
  token: string;
  figmaFile: string;
}

export const PluginGetProvider: React.FC<PluginGetProviderProps> = ({ token, figmaFile }) => {
  if (!token) {
    console.warn('WARNING: Missing plugin token provider. This should not happen if coming from the Recursica plugin');
    return <Redirect to={'/start'} />;
  }
  let disableGithub = false;
  if (getEnvironment().type === 'localhost') {
    console.warn(
      'Disabling Github login because it cant handle localhost callback uris for authentication.  Use gitlab instead',
    );
    disableGithub = true;
  }
  return (
    <View title={'Pick Provider'}>
      <div className={styles.page}>
        <Heading padding={'0 0 100px 0'}>Pick your project provider.</Heading>
        <List spacing={'30px'}>
          <Button
            id={'PickGithub'}
            text={'GitHub'}
            disabled={disableGithub}
            href={`${PluginLoginPath}?token=${token}&providerId=${PROVIDER_ID.github}&figmaFile=${figmaFile || ''}`}
          />
          <Button
            id={'PickGitlab'}
            text={'GitLab'}
            href={`${PluginLoginPath}?token=${token}&providerId=${PROVIDER_ID.gitlab}&figmaFile=${figmaFile || ''}`}
          />
        </List>
      </div>
    </View>
  );
};
