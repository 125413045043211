/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AppStateContext } from 'context/AppStateContext';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import styles from './styles.module.scss';
import { PROVIDER_ID, startAuth } from 'auth/authentication';
import { PATH as StartPath } from 'views/Start';
import { PATH as PluginCompletePath } from 'views/PluginComplete';
import { PATH as PluginGetProviderPath } from 'views/PluginGetProvider';
import { List } from 'components/List/List';
import { Button } from 'components/Button/Button';
interface PluginLoginProps {
  token: string;
  providerId: string;
  figmaFile: string;
  visited: boolean;
  onSetVisited: (visited: boolean) => void;
}

export const PluginLogin: React.FC<PluginLoginProps> = ({ token, providerId, figmaFile, visited, onSetVisited }) => {
  const appContext = useContext(AppStateContext);
  const handleStart = () => {
    // Set visited so if the user presses the back button, it will not automatically connect again
    onSetVisited(true);
    if (token && providerId && providerId !== PROVIDER_ID.local) {
      // Set visited so if the user presses the back button, it will not automatically connect again
      startAuth(
        appContext.appState.accessCode,
        token,
        providerId as PROVIDER_ID,
        PluginCompletePath,
        false,
        true,
        figmaFile,
      );
    }
  };
  useEffect(() => {
    if (visited) {
      return;
    }
    handleStart();
  }, [visited]);
  if (!token) {
    console.warn('WARNING: Missing plugin token. This should not happen if coming from the Recursica plugin');
    return <Redirect to={StartPath} />;
  }
  if (!providerId || providerId === PROVIDER_ID.local) {
    return (
      <Redirect to={`${PluginGetProviderPath}?token=${token}&providerId=${providerId}&figmaFile=${figmaFile || ''}`} />
    );
  }
  return (
    <View title={'Login'}>
      <div className={styles.page}>
        <Heading>Connecting...</Heading>
        <List spacing={'40px'} padding={'0 30px 0 0'}>
          <Button
            id={'all-done'}
            text={'All done'}
            href={`${PluginCompletePath}?providerId=${providerId}&figmaFile=${figmaFile || ''}&result=success`}
          />
          <Button id={'retry'} text={'Retry'} onClick={handleStart} />
        </List>
      </div>
    </View>
  );
};
