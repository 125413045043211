import { forwardRef, InputHTMLAttributes } from 'react';
import './styles.scss';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  onChangeValue?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const TextInput = forwardRef((props: TextInputProps, ref?: React.Ref<HTMLInputElement>) => {
  const { className, type, width, onChangeValue, onChange, ...additionalProps } = props;
  const getClassName = () => {
    let newClassName = 'TextInput';
    if (className) {
      newClassName += ` ${className}`;
    }
    return newClassName;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeValue) {
      onChangeValue(e.target.value, e);
      return;
    }
    if (onChange) {
      onChange(e);
    }
  };
  const styles = {
    width: width || '350px',
  };
  return (
    <input
      {...additionalProps}
      style={styles}
      ref={ref}
      className={getClassName()}
      type={type || 'text'}
      onChange={handleChange}
    />
  );
});
TextInput.displayName = 'TextInput';
