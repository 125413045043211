import React, { useContext } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';
import { AppStateContext } from '../context/AppStateContext';
import { PATH as PATH_AUTHORIZE } from '../views/Authorize';
import { PATH as PATH_GET_PROVIDER } from '../views/GetProvider';

interface AuthenticatedRouteProps extends RouteProps {
  redirectTo?: string;
}

function _addFigmaFileToUri(uri: string, figmaFile: string | null): string {
  if (!uri) {
    return '';
  }
  if (!figmaFile) {
    return uri;
  }
  if (uri.indexOf('figmaFile') !== -1) {
    return uri;
  }
  if (uri.indexOf('?') !== -1) {
    return `${uri}&figmaFile=${figmaFile}`;
  }
  return `${uri}?figmaFile=${figmaFile}`;
}

export const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = (props) => {
  const appState = useContext(AppStateContext);
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search || '');
  const figmaFile = searchParams.get('figmaFile');
  if (!appState.appState.accessCode) {
    return <Redirect to={_addFigmaFileToUri(PATH_AUTHORIZE, figmaFile)} />;
  }
  if (!appState.appState.currentUser) {
    return <Redirect to={_addFigmaFileToUri(props.redirectTo || PATH_GET_PROVIDER, figmaFile)} />;
  }
  return <Route {...props} />;
};
