/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import { View } from '../View';
import { Heading } from '../../components/Heading/Heading';
import { Button } from 'components/Button/Button';
import styles from './styles.module.scss';
import { List } from 'components/List/List';
import { Error } from 'components/Error/Error';
import { processAuthResponse, PROVIDER_ID } from 'auth/authentication';
import { AppStateContext } from 'context/AppStateContext';
import { useError } from 'hooks/useError';
import { Link } from 'react-router-dom';
import { PATH as GetProviderPath } from 'views/GetProvider';
import { PATH as CloningPath } from 'views/Cloning';
import { PATH as BackToFigmaPath } from 'views/BackToFigma';
import { formatErrorDescription } from 'utils/formatErrorDescription';

interface AppLoginCompleteProps {
  token: string;
  errorResult: string;
  errorDescription: string;
  result: string;
  providerId: string;
  figmaFile: string;
}

export const AppLoginComplete: React.FC<AppLoginCompleteProps> = ({
  token,
  errorResult,
  errorDescription,
  result,
  providerId,
  figmaFile,
}) => {
  const appState = useContext(AppStateContext);
  const currentUser = appState.appState.currentUser;
  const authenticated = currentUser && currentUser.repoProviderId === providerId;
  const [success, setSuccess] = useState(authenticated);
  const [error, setError] = useError();
  useEffect(() => {
    if (result !== 'success') {
      return;
    }
    if (!token || !providerId) {
      setSuccess(true);
      return;
    }
    processAuthResponse(appState.appState.accessCode, token, providerId as PROVIDER_ID)
      .then(() => {
        setSuccess(true);
      })
      .catch((error) => {
        setError('Unable to authenticate with Recursica', error);
      });
  }, []);
  if (success) {
    return (
      <View title={'Login Complete'}>
        <div className={styles.page}>
          <Heading padding={'0 0 40px 0'}>Bingo! It worked.</Heading>
          <p>{`Let’s clone a starter set to get you going.`}</p>
          <List spacing={'40px'}>
            <Button
              id={'CloneMaterial'}
              text={'Material Design'}
              disabled={!authenticated}
              href={`${CloningPath}?project=material&figmaFile=${figmaFile || ''}`}
            />
            <Button
              id={'CloneChakra'}
              text={'Chakra UI'}
              disabled={!authenticated}
              href={`${CloningPath}?project=chakra&figmaFile=${figmaFile || ''}`}
            />
          </List>
          <Link id={'ExistingProject'} to={`${BackToFigmaPath}?figmaFile=${figmaFile || ''}`}>
            I already have a code project
          </Link>
        </div>
      </View>
    );
  }
  if (result === 'success' && !error) {
    return null;
  }
  return (
    <View title={'Login Error'}>
      <div className={styles.page}>
        <Heading padding={'0 0 20px 0'}>Error</Heading>
        <p className={styles.errorDescription}>{`It looks like an error occurred during authentication${
          providerId ? `with ${providerId}` : ''
        }`}</p>
        <Error message={error || formatErrorDescription(errorDescription)} />
        <List padding={'60px 0 0 0'} spacing={'30px'}>
          <Button id={'Retry'} text={'Retry'} href={`${GetProviderPath}?figmaFile=${figmaFile || ''}`} />
          <Button id={'Cancel'} text={'Cancel'} href={`/start?figmaFile=${figmaFile || ''}`} />
        </List>
      </div>
    </View>
  );
};
