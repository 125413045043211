import { withQueryParamsMapped, StringParam, DecodedValueMap } from 'use-query-params';
import { Authorize } from './Authorize';

const queryConfig = {
  figmaFile: StringParam,
};

function mapQueryParamsToProps(query: DecodedValueMap<typeof queryConfig>) {
  return {
    figmaFile: query.figmaFile,
  };
}

export default withQueryParamsMapped(queryConfig, mapQueryParamsToProps, Authorize);
