import { createGetApi } from 'api-layer';
import { getEnvironment, formatEnvUrl } from 'utils/environment';
import { get } from '../../utils/apiUtils';

const APIS: any = {
  localhost: '/recursica-dev/us-central1/app-checkAccessCode',
  'recursica-dev': '/api/checkAccessCode',
  recursica: '/api/checkAccessCode',
};

export interface ApiResponse {
  valid: 'valid' | 'notValid' | 'notFound';
  accessCode: string;
}

function getCheckAccessCode(accessCode: string | null | undefined): Promise<ApiResponse> {
  const env = getEnvironment();
  const url = formatEnvUrl(env, APIS[getEnvironment().type]);
  const params = {
    accessCode,
  };
  return get<ApiResponse>(url, { params });
}

export const apiGetCheckAccessCode = createGetApi(getCheckAccessCode, 'getCheckAccessCode.mock.json');
