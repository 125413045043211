import { AppState, DEFAULT_APP_STATE } from 'context/AppStateContext';
import { setCookie, getCookie } from '../utils/cookieUtils';

const COOKIE_NAME = 'appState';
export const CURRENT_VERSION = 1;

export function loadAppState(): AppState {
  let data: any;
  const value = getCookie(COOKIE_NAME);
  if (value) {
    try {
      data = JSON.parse(value);
    } catch (e) {
      data = undefined;
    }
  }
  if (data && data.version <= CURRENT_VERSION) {
    return data.appState as AppState;
  }
  return { ...DEFAULT_APP_STATE };
}

export function saveAppState(state: AppState): void {
  const data: any = {
    version: CURRENT_VERSION,
  };
  data.appState = { ...DEFAULT_APP_STATE, ...state };
  delete data.appState.currentUser; // Never persist the current user as its handled by auth
  data.version = CURRENT_VERSION;
  setCookie(COOKIE_NAME, JSON.stringify(data));
}
