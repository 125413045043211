export interface Environment {
  type: 'recursica' | 'recursica-dev' | 'localhost' | 'jest';
  host: string;
  url: string;
  development: boolean;
  port: number;
  gitlabAccessToken?: string;
  githubAccessToken?: string;
  env: any;
}

const PROD_ENVIRONMENT: Environment = {
  type: 'recursica',
  host: 'app.recursica.com',
  url: 'https://app.recursica.com',
  development: false,
  port: 0,
  env: process.env,
};

const DEV_ENVIRONMENT: Environment = {
  type: 'recursica-dev',
  host: 'dev.recursica.com',
  url: 'https://dev.recursica.com',
  development: true,
  port: 0,
  env: process.env,
};

const LOCAL_ENVIRONMENT: Environment = {
  type: 'localhost',
  host: 'localhost',
  url: 'http://localhost',
  port: 5001,
  development: true,
  env: process.env,
  gitlabAccessToken: process.env.GITLAB_ACCESS_TOKEN,
  githubAccessToken: process.env.GITHUB_ACCESS_TOKEN,
};

let ENVIRONMENT: Environment;

export function getEnvironment(env?: string): Environment {
  if (env) {
    switch (env) {
      case 'recursica':
      case 'prod':
      case 'production':
        return PROD_ENVIRONMENT;
      case 'recursica-dev':
        return DEV_ENVIRONMENT;
      case 'localhost':
      case 'dev':
      case 'development':
        return LOCAL_ENVIRONMENT;
      default:
        throw new Error(`Invalid env request: ${env}`);
    }
  }
  if (ENVIRONMENT) {
    return ENVIRONMENT;
  }
  if (process.env.NODE_ENV === 'development') {
    ENVIRONMENT = LOCAL_ENVIRONMENT;
    return ENVIRONMENT;
  }
  const project = process.env.REACT_APP_GCLOUD_PROJECT;
  switch (project) {
    case 'recursica':
      ENVIRONMENT = PROD_ENVIRONMENT;
      return ENVIRONMENT;
    case 'recursica-dev':
      ENVIRONMENT = DEV_ENVIRONMENT;
      return ENVIRONMENT;
    default:
      throw new Error(`Invalid process.env.REACT_APP_GCLOUD_PROJECT=${project}`);
  }
}

export function formatEnvUrl(env: Environment, url?: string) {
  return `${env.url}${env.port ? `:${env.port}` : ''}${url || ''}`;
}
