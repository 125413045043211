export interface HeadingProps {
  text?: string;
  h1?: boolean;
  h2?: boolean;
  padding?: string;
  children?: React.ReactNode;
}

export const Heading = (props: HeadingProps) => {
  const { text, h1, children } = props;
  const styles: any = {};
  if (props.padding) {
    styles.padding = props.padding;
  }
  if (h1) {
    return <h1 style={styles}>{children || text}</h1>;
  }
  return <h2 style={styles}>{children || text}</h2>;
};
