import { withQueryParamsMapped, StringParam, DecodedValueMap } from 'use-query-params';
import { OauthDescription } from './OauthDescription';

const queryConfig = {
  providerId: StringParam,
  figmaFile: StringParam,
};

function mapQueryParamsToProps(query: DecodedValueMap<typeof queryConfig>) {
  return {
    providerId: query.providerId,
    figmaFile: query.figmaFile,
  };
}

export default withQueryParamsMapped(queryConfig, mapQueryParamsToProps, OauthDescription);
