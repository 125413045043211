import { forwardRef } from 'react';
import '../Button/styles.scss';

export interface SubmitButtonProps {
  id: string;
  text?: string;
  value?: string;
  onClick?: (id?: string) => void;
  disabled?: boolean;
  className?: string;
}

export const SubmitButton = forwardRef((props: SubmitButtonProps | any, ref?: React.Ref<HTMLButtonElement>) => {
  const { text, value, onClick, className, children, href, target, complex, ...additionalProps } = props;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    if (onClick) {
      onClick(event);
    }
  };
  const getClassName = () => {
    let newClassName = 'Button';
    if (className) {
      newClassName += ` ${className}`;
    }
    return newClassName;
  };
  return (
    <input
      {...additionalProps}
      type={'submit'}
      ref={ref}
      onClick={handleClick}
      className={getClassName()}
      value={value || text}
    ></input>
  );
});
SubmitButton.displayName = 'SubmitButton';
