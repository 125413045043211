import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import { ReactComponent as Logo } from './logo.svg';

export interface HeaderProps {
  hideLogo?: boolean;
  backToWebsiteButton?: boolean;
  children?: React.ReactNode;
}

export const Header: React.FC<HeaderProps> = ({ hideLogo, children, backToWebsiteButton }) => {
  const renderRightContent = () => {
    if (children) {
      return children;
    }
    if (backToWebsiteButton) {
      return (
        <Link id={'BackToWebsite'} to={'https://recursica.com'}>
          Back to Website
        </Link>
      );
    }
    return null;
  };
  const renderRight = () => {
    if (!children && !backToWebsiteButton) {
      return null;
    }
    return <div className={styles.right}>{renderRightContent()}</div>;
  };
  return (
    <div className={styles.header}>
      {!hideLogo && (
        <div className={styles.left}>
          <a
            id={'LogoOverlay'}
            href={'https://recursica.com'}
            className={'LogoOverlay'}
            aria-label={'Go to Recursica.com'}
          >
            <Logo />
          </a>
        </div>
      )}
      <div className={styles.center} />
      {renderRight()}
    </div>
  );
};
